import axios from "axios";

export const getCards = async () => {
  try {
    const response = await axios.post('https://pokemon.marianmzigot.com/backend/pkmn.php');
    const data = response.data;
    return data;
  } catch(error) {
    throw new Error('Error fetching data: ' + error.message);  // Proper error handling
  }
}