import ShinyCard from "@mountainpass/react-pokemon-cards";
import { useEffect, useState } from "react";

const CardItem = ({ src, subType, rarity }) => {
  const [ imageLoaded, setImageLoaded ] = useState(false);
  const placeholderImg = './img/card_back.jpg';

  useEffect(() => {
    const img = new Image();
    img.src = src;
    img.onload = () => setImageLoaded(true);
  }, [src]);

  return (
    <ShinyCard subType={subType} rarity={imageLoaded ? rarity : ""}>
      <img src={imageLoaded ? src : placeholderImg} alt="" />
    </ShinyCard>
  );
};

export default CardItem;