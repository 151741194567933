import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Cards from './Cards';

function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Cards />}/>
      </Routes>
    </BrowserRouter>
  )
}

export default Router;