import '@mountainpass/react-pokemon-cards/dist/css/cards.css'
import { useEffect, useState } from 'react';
import { getCards } from '../services/service';
import { Alert, Box, Button, Card, CardContent, CircularProgress, Fade, Grid, Stack, Typography } from '@mui/material';
import CardItem from './CardItem';
import { OpenInNew } from '@mui/icons-material';

function Cards() {
  //var rarities = ['custom','common','radiant rare','rare holo','rare holo galaxy','rare holo v','rare holo vmax','rare holo vstar','rare rainbow','rare rainbow alt','rare secret','rare ultra','uncommon'];
  //var subtypes = ['basic','basic v','basic v fusion strike','basic v rapid strike','basic v single strike','item','pokémon tool','stadium','stage 1','stage 2','stage 2 rapid strike','supporter','vmax','vmax rapid strike','vmax single strike','vstar'];
  const [ cards, setCards ] = useState([]);
  const [ loading, setLoading ] = useState(true);
  const [ alert, setAlert ] = useState(false);
  const [ alertText, setAlertText ] = useState("");
  const [ alertCollapse, setAlertCollapse ] = useState(false);
  useEffect(() => {
    const fetchCards = async () => {
      try {
        const data = await getCards();
        setCards(data);
      } catch (error) {
        setAlert(true);
        setAlertText(error.message);
        setAlertCollapse(true);
      } finally {
        setLoading(false);
      }
    };
    fetchCards();
  }, []);


  return (
    <>
      {loading && 
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: '100vh'
        }}
      >
        <CircularProgress color='warning' size={200}/>
      </div>}
      {!loading && 
        <Grid container spacing={6} my={5}>
        {alert &&
          <Fade in={alertCollapse}>
            <Alert variant="filled" severity="error" sx={{ mb: 2, width: '100%' }}>
              {alertText}
            </Alert>
          </Fade>
        }
        {cards && cards.map(function(card) {
          return (
            <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
              <CardItem src={card.images.large} rarity={card.rarityInternal} />
              <Card>
                <CardContent>
                  <Box p={1} display="flex" justifyContent="center" alignItems="center">
                    <img alt={card.name} src={card.set.images.logo} style={{ maxHeight: '65px' }} />
                  </Box>
                  <Typography sx={{ color: 'warning.main' }} p={1} variant="h6" component="div" align="center">
                    {/*{card.number} / {card.set.printedTotal}*/}
                    {card.cardmarket.prices[card.cardMarketPriceProperty]} €
                  </Typography>
                  <Typography pb={1} variant="p" component="div" align="center">
                    {card.rarity} - {card.raritySub}
                  </Typography>
                  <Stack spacing={1} direction="row" display="flex" justifyContent="center" alignItems="center">
                    {card.tcgplayer.url && <Button href={card.tcgplayer.url} size="small" target="_blank" variant="contained" color="primary" endIcon={<OpenInNew />}>TCG</Button>}
                    {card.cardmarket.url && <Button href={card.cardmarket.url} size="small" target="_blank" variant="contained" color="info" endIcon={<OpenInNew />}>CM</Button>}
                    {card.pricecharting.url && <Button href={card.pricecharting.url} size="small" target="_blank" variant="contained" color="success" endIcon={<OpenInNew />}>PCh</Button>}
                  </Stack>
                </CardContent>
              </Card>
            </Grid>
          )
        })}
        </Grid>
      }
      {/*
      <div>
        {rarities.map(function(r) {
          return (
            <ShinyCard rarity={r} style={{ width: '300px', height: '400px' }}>
              {r}
            </ShinyCard>
          )
        })}
      </div>
      */}
    </>
  )
}

export default Cards;